import React from "react";
import styled from "styled-components";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";
import { Link, useLocation } from "react-router-dom";
import { baseUrl } from "../../config";

export default function Sidebar({ sidebaropen, toggleSidebar }) {
  const location = useLocation();
  return (
    <>
      <Wrapper className="animate darkBg" sidebaropen={sidebaropen}>
        <SidebarHeader className="flexSpaceCenter">
          <Link to="/" className="flexNullCenter">
            <LogoIcon variant="white" isSideBar={true} />
          </Link>

          <CloseBtn
            onClick={() => toggleSidebar(!sidebaropen)}
            className="animate pointer"
          >
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>
        <UlStyle className="flexNullCenter flexColumn">
          {/* <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/connect-and-collaborate"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/connect-and-collaborate"
                    ? "active"
                    : ""
                } `}
              >
                Marketplace
              </span>
            </Link>
          </li> */}
            
          {/* <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/marketplace"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/marketplace"
                    ? "active"
                    : ""
                } `}
              >
                Marketplace
              </span>
            </Link>
          </li> */}

          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/cards"
              spy={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/cards" ? "active" : ""
                } `}
              >
                {" "}
                Membership
              </span>
            </Link>
          </li>
          {/* <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/work-buddy"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/work-buddy" ? "active" : ""
                } `}
              >
                Work Buddy
              </span>
            </Link>
          </li> */}
 <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/about"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/about"
                    ? "active"
                    : ""
                } `}
              >
                About
              </span>
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/rent-a-robot"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/rent-a-robot" ? "active" : ""
                } `}
              >
                Rent A Robot
              </span>
            </Link>
          </li>
          <li className="semiBold font15 pointer">
            <Link
              onClick={() => toggleSidebar(!sidebaropen)}
              activeClass="active"
              className="text-white"
              style={{ padding: "10px 15px" }}
              to="/owner-operators"
              spy={true}
              smooth={true}
              offset={-60}
            >
              <span
                className={` cl-white ${
                  location.pathname === "/owner-operators" ? "active" : ""
                } `}
              >
                Owner Operators
              </span>
            </Link>
          </li>
        </UlStyle>

        {/* <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="text-white"
            style={{ padding: "10px 15px" }}
            to="/"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="text-white"
            style={{ padding: "10px 15px" }}
            to="/rent-a-robot"
            spy={true}
            smooth={true}
            offset={-60}
          >
           Rent a Robot
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="projects"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Projects
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="pricing"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Pricing
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebaropen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li>
      </UlStyle> */}
        <UlStyle className="d-flex">
          <li className="semiBold font15 pointer flexCenter gap-2 rounded-radius waitlist-btn  padding">
            <Link to="/serveus911" onClick={() => toggleSidebar(!sidebaropen)}>
              <span
              // className={`cl-white ${
              //   location.pathname === "/serveus911" ? "active" : ""
              // } `}
              >
                <i className="bi bi-telephone cl-white"></i>{" "}
                <span className="cl-white">Service 911</span>{" "}
              </span>
            </Link>
          </li>
          <div className="d-flex justify-content-around align-items-center">
            {/* <li className="semiBold font15 pointer cursor-pointer">
          <a
            href="/"
            style={{ padding: "10px 30px 10px 0" }}
            className="whiteColor"
          >
            Log in
          </a>
        </li> */}

            <li className="semiBold font15 pointer flexCenter">
            <Link to={baseUrl}

                className="radius8 lightBg"
                style={{ padding: "10px 15px" }}
              >
                Log in
              </Link>
            </li>

            <li className="semiBold font15 pointer flexCenter">
              <Link to={`${baseUrl}/auth/choose-profile`}

                className="radius8 lightBg"
                style={{ padding: "10px 15px" }}
              >
                Sign up
              </Link>
            </li>
          </div>
        </UlStyle>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebaropen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  flex-direction: column;
  li {
    margin: 20px 0;
  }
`;
// const Btn911 = styled.ul`
//   margin-bootom: 0 !important;
//   border-radius: 50px;
//   padding: 0;
// `;
